import axios from '@axios'

export default {
  namespaced: true,
  state: {
  
      jobColumns: [],
    
  },
  mutations: {
    SET_JOB_DATA(state, data) {
      state.data = data
    },
    PUSH_DATA(state, data) {
      state.data.results.push(resp.data)
    },
    UPDATE_DATA(state, data) {
      state.data.results = state.data.results.map(it => (it.id != id ? it : data))
    },
    DELETE_DATA(state, id) {
      state.data.results = state.data.results.filter(it => it.id != id)
    },
  },
  getters: {
    GET_RESULTS(data) {
      return data.results
    },
  },
  actions: {
    async getJob({ commit }, { id }) {
  // console.log("id" , this.$route.params.id);

  return await axios.get(`jobs`)
  // .then(resp => {
  //   commit('SET_DATA', resp.data)
  //   return resp
  // })
    },
    async getOne({ commit }, id) {
      return await axios
        .get(`attachment/${id}/`)
        .then(resp => resp.data)
    },
  },
}
