export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/Home.vue'),
    meta: {
      isAuth: true,
    },
    children: [
      {
        path: '/statistics',
        modelName: 'employee',
        name: 'statistics',
        component: () => import('@/views/statistics/Statistcs.vue'),
        meta: {
          isDashboard: true,
        },
      },

      {
        path: '/employee/list',
        name: 'employee-list',
        component: () => import('@/views/employees/EmployeeListView.vue'),
        meta: {
          isDashboard: true,
          permissions: ['employee.view_employee'],
          model: 'employee',
        },
      },
      {
        path: '/devices-list',
        name: 'devices-list',
        component: () => import('@/views/devices/DeviceListView.vue'),
        meta: {
          isDashboard: true,
          permissions: ['base.view_logindevice'],
          model: 'logindevice',
        },
      },

      {
        path: '/employee/add/',
        name: 'employee-add',
        component: () => import('@/views/employees/Employee-Add.vue'),
        meta: {
          isDashboard: true,
        },
      },
      {
        path: '/employee/lis/view/:id',
        name: 'employee-lis-view',
        component: () => import('@/views/employees/EmployeeDetalis.vue'),
        meta: {
          isDashboard: true,
          model: 'employee',
          isForm: true,
        },
      },
      {
        path: '/employee-bank/',
        name: 'employee-bank',
        component: () => import('@/views/bank/ListView.vue'),
        meta: {
          isDashboard: true,
          permissions: ['employee.view_employeebank'],
          model: 'employeebank',
        },
      },
      {
        path: '/contract-type/',
        name: 'contract-type',
        component: () => import('@/views/contract/ContractTypeView.vue'),
        meta: {
          isDashboard: true,
          permissions: ['employee.view_employeecontracttype'],
          model: 'employeecontracttype',
        },
      },
      {
        path: '/employee-contract/list',
        name: 'employee-contract-list',
        component: () => import('@/views/contract/ListView.vue'),
        meta: {
          isDashboard: true,
          permissions: ['employee.view_employeecontract'],
          model: 'employeecontract',
        },
      },
      {
        path: '/employee-contract/form/:id?',
        name: 'employee-contract-form',
        component: () => import('@/views/contract/FormView.vue'),
        meta: {
          isDashboard: true,
          model: 'employeecontract',
          isForm: true,
        },
      },

      {
        path: '/jobs/job-title',
        name: 'job-title',
        component: () => import('@/views/jobs/JobTitleView.vue'),
        meta: {
          isDashboard: true,
          permissions: ['employee.view_jobtitle'],
          model: 'jobtitle',
        },
      },
      {
        path: '/jobs/job-location',
        name: 'job-location',
        component: () => import('@/views/jobs/JobLocationView.vue'),
        meta: {
          isDashboard: true,
          permissions: ['employee.view_joblocation'],
          model: 'joblocation',
        },
      },
      {
        path: '/departments',
        name: 'departments',
        component: () => import('@/views/departments/DepartmentView.vue'),
        meta: {
          isDashboard: true,
          permissions: ['employee.view_department'],
          model: 'department',
        },
      },
      {
        path: '/department-unit',
        name: 'department-unit',
        component: () => import('@/views/departments/DepartmentUnitView.vue'),
        meta: {
          isDashboard: true,
          permissions: ['employee.view_departmentunit'],
          model: 'departmentunit',
        },
      },
      {
        path: '/Equipment-Brand',
        name: 'Equipment-Brand',
        component: () => import('@/views/equipment/EquipmentBrandView.vue'),
        meta: {
          isDashboard: true,
          permissions: ['equipment.view_equipmentbrand'],
          model: 'equipmentbrand',
        },
      },
      {
        path: '/Equipments',
        name: 'Equipments',
        component: () => import('@/views/equipment/EquipmentView.vue'),
        meta: {
          isDashboard: true,
          permissions: ['equipment.view_equipment'],
          model: 'equipment',
        },
      },
      {
        path: '/Equipment-Details/:id',
        name: 'Equipment-Details',
        component: () => import('@/views/equipment/equipmentsDetails.vue'),
        meta: {
          isDashboard: true,
        },
      },

      {
        path: '/Work-schedule/:id',
        name: 'Work-schedule-show',
        component: () =>
          import('@/views/work-schedule/WorkScheduleEmployees.vue'),
        meta: {
          isDashboard: true,
        },
      },
      {
        path: '/Work-schedule',
        name: 'Work-schedule',
        component: () => import('@/views/work-schedule/WorkScheduleView.vue'),
        meta: {
          isDashboard: true,
          permissions: ['employee.view_workschedule'],
          model: 'workschedule',
        },
      },
      {
        path: '/Attendance',
        name: 'Attendance',
        component: () => import('@/views/attendance/AttendanceListView.vue'),
        meta: {
          isDashboard: true,
          permissions: ['attendance_core.view_actualattendance'],
          model: 'actualattendance',
        },
      },

      {
        path: '/Attendance/:id',
        name: 'Attendance-employee-list',
        component: () => import('@/views/attendance/AttendanceEmployee.vue'),
        meta: {
          isDashboard: true,
        },
      },
      {
        path: '/attendance-report',
        name: 'attendance-report',
        component: () => import('@/views/attendance/ReportView.vue'),

        meta: {
          isDashboard: true,
          permissions: ['attendance_core.view_actualattendance'],
          model: 'actualattendance',
        },
      },
      {
        path: '/attendance-adjustment',
        name: 'attendance-adjustment-list',
        component: () => import('@/views/attendence-adjustment/ListView.vue'),
        meta: {
          isDashboard: true,
          permissions: ['attendance_adjustment.view_changerequest'],
          model: 'changerequest',
        },
      },
      {
        path: '/attendance-adjustment/add',
        name: 'attendance-adjustment-add',
        component: () => import('@/views/attendence-adjustment/AddView.vue'),
        meta: {
          isDashboard: true,
          model: 'changerequest',
          isForm: true,
        },
      },
      {
        path: '/attendance-adjustment/edit/:id',
        name: 'attendance-adjustment-edit',
        component: () => import('@/views/attendence-adjustment/EditView.vue'),
        meta: {
          isDashboard: true,
          model: 'changerequest',
          isForm: true,
        },
      },
      {
        path: '/Work-shift',
        name: 'Work-shift',
        component: () => import('@/views/work-schedule/WorkShift.vue'),
        meta: {
          isDashboard: true,
          permissions: ['attendance_core.view_workshift'],
          model: 'workshift',
        },
      },

      // {
      //   path: '/Allowance',
      //   name: 'allowance',
      //   component: () => import('@/views/allowance/allowance.vue'),
      //   meta: {
      //     isDashboard: true,
      //     permissions: ['attendance_core.view_workshift'],
      //     model: 'workshift',
      //   },
      // },

      {
        path: '/approve-group',
        name: 'approve-group',
        component: () => import('@/views/approve/ApproveGroupView.vue'),
        meta: {
          isDashboard: true,
          permissions: ['approve_core.view_approvegroup'],
          model: 'approvegroup',
        },
      },

      // {
      //   path: '/Approve-Role-Employees/:id',
      //   name: 'timeOffRoles-show',
      //   component: () => import('@/views/timeOff/rolesEmployee.vue'),
      // },

      {
        path: '/timeoff-types',
        name: 'timeoff-types',
        component: () => import('@/views/timeOff/types/ListView.vue'),
        meta: {
          isDashboard: true,
          permissions: ['timeoff.view_timeofftype'],
          model: 'timeofftype',
        },
      },
      {
        path: '/timeoff-types/add/',
        name: 'timeoff-type-add',
        component: () => import('@/views/timeOff/types/AddView.vue'),
        meta: {
          isDashboard: true,
          model: 'timeofftype',
          isForm: true,
        },
      },
      {
        path: '/timeoff-types/edit/:id',
        name: 'timeoff-type-edit',
        component: () => import('@/views/timeOff/types/EditView.vue'),
        meta: {
          isDashboard: true,
          model: 'timeofftype',
          isForm: true,
        },
      },
      {
        path: '/timeoff-requests',
        name: 'timeoff-request-list',
        component: () => import('@/views/timeOff/requests/ListView.vue'),
        meta: {
          isDashboard: true,
          permissions: ['timeoff.view_timeoffrequest'],
          model: 'timeoffrequest',
        },
      },
      {
        path: '/timeoff-request-payment-reason',
        name: 'timeoff-request-payment-list',
        component: () =>
          import('@/views/timeOff/requests/RequestFullPayment.vue'),
        meta: {
          isDashboard: true,
          permissions: ['timeoff.view_timeoffrequestfullpayment'],
          model: 'timeoffrequestfullpayment',
        },
      },

      {
        path: '/timeoff-request/form/:id?',
        name: 'timeoff-request-form',
        component: () => import('@/views/timeOff/requests/FormView.vue'),
        meta: {
          isDashboard: true,
          model: 'timeoffrequestfullpayment',
          isForm: true,
        },
      },
      {
        path: '/timeoff-balances/list',
        name: 'timeoff-balances-list',
        component: () => import('@/views/timeOff/balance/BalanceListView.vue'),
        meta: {
          isDashboard: true,
          permissions: ['timeoff.view_timeofftypeemployeebalance'],
          model: 'timeofftypeemployeebalance',
        },
      },
      {
        path: '/timeoff-balance-updates/:id',
        name: 'timeoff-balance-updates',
        component: () =>
          import('@/views/timeOff/balance/BalanceUpdateView.vue'),
        meta: {
          isDashboard: true,
          permissions: ['timeoff.view_timeofftypeemployeebalanceupdate'],
          model: 'timeofftypeemployeebalanceupdate',
        },
      },
      {
        path: '/employee-assessment/',
        name: 'employee-assessment-list',
        component: () => import('@/views/assessment/ListView.vue'),
        meta: {
          isDashboard: true,
          permissions: ['employee.view_employeeassessment'],
          model: 'employeeassessment',
        },
      },

      {
        path: '/employee-assessment-form/:id?',
        name: 'employee-assessment-form',
        component: () => import('@/views/assessment/FormView.vue'),
        meta: {
          isDashboard: true,
          model: 'employeeassessment',
          isForm: true,
        },
      },

      {
        path: '/employee-assessment-line-type/',
        name: 'employee-assessment-line-type-list',
        component: () => import('@/views/assessment-type/ListView.vue'),
        meta: {
          isDashboard: true,
          permissions: ['employee.view_employeeassessmentlinetype'],
          model: 'employeeassessmentlinetype',
        },
      },  
      {
        path: '/employee-assessment-line-type-form/:id?',
        name: 'employee-assessment-line-type-form',
        component: () => import('@/views/assessment-type/FormView.vue'),
        meta: {
          isDashboard: true,
          model: 'employeeassessmentlinetype',
          isForm: true,
        },
      },
      {
        path: '/employee-assessment-form-template/',
        name: 'employee-assessment-form-template-list',
        component: () => import('@/views/assessment-template/ListView.vue'),
        meta: {
          isDashboard: true,
          permissions: ['employee.view_employeeassessmentformtemplate'],
          model: 'employeeassessmentformtemplate',
        },
      },
      {
        path: '/employee-assessment-form-template-form/:id?',
        name: 'employee-assessment-form-template-form',
        component: () => import('@/views/assessment-template/FormView.vue'),
        meta: {
          isDashboard: true,
          model: 'employeeassessmentformtemplate',
          isForm: true,
        },
      },

      {
        path: '/employee-assessment-forma/',
        name: 'employee-assessment-forma-list',
        component: () => import('@/views/assessment-form/ListView.vue'),
        meta: {
          isDashboard: true,
          permissions: ['employee.view_employeeassessmentform'],
          model: 'employeeassessmentform',
        },
      },
      {
        path: '/employee-assessment-forma/:id?',
        name: 'employee-assessment-forma-form',
        component: () => import('@/views/assessment-form/FormView.vue'),
        meta: {
          isDashboard: true,
          model: 'employeeassessmentform',
          isForm: true,
        },
      },

      {
        path: '/employee-assessment-batch/',
        name: 'employee-assessment-batch-list',
        component: () => import('@/views/assessment-batch/ListView.vue'),
        meta: {
          isDashboard: true,
          permissions: ['employee.view_employeeassessmentbatch'],
          model: 'employeeassessmentbatch',
        },
      },
      {
        path: '/employee-assessment-batch-form/:id?',
        name: 'employee-assessment-batch-form',
        component: () => import('@/views/assessment-batch/FormView.vue'),
        meta: {
          isDashboard: true,
          model: 'employeeassessmentbatch',
          isForm: true,
        },
      },

      {
        path: '/assessment-to-do',
        name: 'assessment-to-do',
        component: () => import('@/views/assessment/AssessmentToDo.vue'),
        meta: {
          isDashboard: true,
          permissions: ['employee.view_employeeassessmentline'],
          model: 'employeeassessmentline',
        },
      },

      {
        path: '/assessment-to-do/user',
        name: 'assessment-to-do-user',
        component: () => import('@/views/user-view/AssessmentsToDoView.vue'),
        meta: {
          layout: 'user',
          permissions: ['employee.view_employeeassessmentline'],
          model: 'employeeassessmentline',
        },
      },

      {
        path: '/employee-assessment-salary-adjustment-rule/',
        name: 'employee-assessment-salary-adjustment-rule-list',
        component: () =>
          import('@/views/assessments-salary-rules/ListView.vue'),
        meta: {
          isDashboard: true,
          permissions: ['employee.view_employeeassessmentsalaryadjustmentrule'],
          model: 'employeeassessmentsalaryadjustmentrule',
        },
      },
      {
        path: '/employee-assessment-salary-adjustment-rule/:id?',
        name: 'employee-assessment-salary-adjustment-rule-form',
        component: () =>
          import('@/views/assessments-salary-rules/FormView.vue'),
        meta: {
          isDashboard: true,
          model: 'employeeassessmentsalaryadjustmentrule',
          isForm: true,
        },
      },

      {
        path: '/payroll/payslip-batch',
        name: 'payroll-payslip-batch-list',
        component: () => import('@/views/payroll/payslip-batch/ListView.vue'),
        meta: {
          isDashboard: true,
          permissions: ['payroll.view_payslipbatch'],
          model: 'payslipbatch',
        },
      },
      {
        path: '/payroll/payslip-batch-form/:id?',
        name: 'payroll-payslip-batch-form',
        component: () => import('@/views/payroll/payslip-batch/FormView.vue'),
        meta: {
          isDashboard: true,
          model: 'payslipbatch',
          isForm: true,
        },
      },

      {
        path: '/payroll/payslip-updates',
        name: 'payroll-payslip-updates',
        component: () => import('@/views/payroll/PayslipUpdateView.vue'),
        meta: {
          isDashboard: true,
          permissions: ['payroll.view_payslipupdate'],
          model: 'payslipupdate',
        },
      },
      {
        path: '/payroll/payslip/list',
        name: 'payroll-payslip-list',
        component: () => import('@/views/payroll/payslip/ListView.vue'),
        meta: {
          isDashboard: true,
          permissions: ['payroll.view_payslip'],
          model: 'payslip',
        },
      },

      {
        path: '/payroll/payslip/my-payslip',
        name: 'employee-payslips',
        component: () =>
          import('@/views/payroll/payslip/CurrentUserPayslips.vue'),
        meta: {
          isDashboard: true,
        },
      },

      {
        path: '/payroll/payslip/my-payslip/user',
        name: 'employee-payslips-user',
        component: () => import('@/views/user-view/MyPayslipsUserView'),
        meta: {
          layout: 'user',
        },
      },

      {
        path: '/payroll/payslip/form/:id?',
        name: 'payroll-payslip-form',
        component: () => import('@/views/payroll/payslip/FormView.vue'),
        meta: {
          isDashboard: true,
          model: 'payslip',
          isForm: true,
        },
      },
      {
        path: '/brands',
        name: 'brands-list',
        component: () => import('@/views/brands/ListView.vue'),
        meta: {
          isDashboard: true,
          model: 'brand',
          isForm: true,
        },
      },

      {
        path: '/attachment-type',
        name: 'attachment-type-list',
        component: () => import('@/views/attachment-type/ListView.vue'),
        meta: {
          isDashboard: true,
          model: 'attachment-type',
          isForm: true,
        },
      },
      {
        path: '/companies',
        name: 'companies-list',
        component: () => import('@/views/company/ListView.vue'),
        meta: {
          isDashboard: true,
          model: 'company',
          isForm: true,
        },
      },
      {
        path: '/projects',
        name: 'projects-list',
        component: () => import('@/views/project/ListView.vue'),
        meta: {
          isDashboard: true,
          model: 'project',
          isForm: true,
        },
      },
      {
        path: '/states',
        name: 'states-list',
        component: () => import('@/views/state/ListView.vue'),
        meta: {
          isDashboard: true,
          model: 'state',
          isForm: true,
        },
      },
      {
        path: '/universities',
        name: 'universities-list',
        component: () => import('@/views/university/ListView.vue'),
        meta: {
          isDashboard: true,
          model: 'university',
          isForm: true,
        },
      },

      {
        path: '/skill-types',
        name: 'skill-types-list',
        component: () =>
          import('@/views/recruitment/skills/skill-type/ListView.vue'),
        meta: {
          isDashboard: true,
          model: 'skill-type',
          isForm: true,
        },
      },
      {
        path: '/skill-levels',
        name: 'skill-levels-list',
        component: () =>
          import('@/views/recruitment/skills/skill-level/ListView.vue'),
        meta: {
          isDashboard: true,
          model: 'skill-level',
          isForm: true,
        },
      },
      {
        path: '/skills',
        name: 'skills-list',
        component: () =>
          import('@/views/recruitment/skills/skill/ListView.vue'),
        meta: {
          isDashboard: true,
          model: 'skill',
          isForm: true,
        },
      }, {
        path: '/action',
        name: 'actions-list',
        component: () =>
          import('@/views/recruitment/actions/action/ListView.vue'),
        meta: {
          isDashboard: true,
          model: 'skill',
          isForm: true,
        },
      }, {
        path: '/application-stage-action',
        name: 'application-stage-action',
   
        component: () => import('@/views/recruitment/jobs/ApplicationStagesAction.vue'),
        meta: {
          isDashboard: true,
          model: 'skill',
          isForm: true,
        },
      },
      {
        path: '/workplace-types',
        name: 'workplace-types-list',
        component: () =>
          import('@/views/recruitment/workplace-type/ListView.vue'),
        meta: {
          isDashboard: true,
          model: 'workplace-type',
          isForm: true,
        },
      },

      {
        path: '/certification-info',
        name: 'certification-info-list',
        component: () =>
          import('@/views/recruitment/certification-info/ListView.vue'),
        meta: {
          isDashboard: true,
          model: 'certification-info',
          isForm: true,
        },
      },

      {
        path: '/application',
        name: 'application-list',
        component: () => import('@/views/recruitment/application/ListView.vue'),
        meta: {
          isDashboard: true,
          model: 'application',
          isForm: true,
        },
      },
      {
        path: '/application-form/:id?',
        name: 'application-form',
        component: () => import('@/views/recruitment/application/FormView.vue'),
        meta: {
          isDashboard: true,
          model: 'application',
          isForm: true,
        },
      },

      {
        path: '/application/application-skill',
        name: 'application-skill-list',
        component: () =>
          import(
            '@/views/recruitment/application/application-skill/ListView.vue'
          ),
        meta: {
          isDashboard: true,
          model: 'application-skill',
          isForm: true,
        },
      },
      {
        path: '/application/application-skill-form/:id?',
        name: 'application-skill-form',
        component: () =>
          import(
            '@/views/recruitment/application/application-skill/FormView.vue'
          ),
        meta: {
          isDashboard: true,
          model: 'application-skill',
          isForm: true,
        },
      },

      {
        path: '/application/application-source',
        name: 'application-source-list',
        component: () =>
          import(
            '@/views/recruitment/application/application-source/ListView.vue'
          ),
        meta: {
          isDashboard: true,
          model: 'application-source',
          isForm: true,
        },
      },
      {
        path: '/application/application-stage',
        name: 'application-stage-list',
        component: () =>
          import(
            '@/views/recruitment/application/application-stage/ListView.vue'
          ),
        meta: {
          isDashboard: true,
          model: 'application-stage',
          isForm: true,
        },
      },

      {
        path: '/hiring-request',
        name: 'hiring-request-list',
        component: () =>
          import('@/views/recruitment/hiring-request/ListView.vue'),
        meta: {
          isDashboard: true,
          model: 'hiring-request',
          isForm: true,
        },
      },
      {
        path: '/hiring-request-form/:id?',
        name: 'hiring-request-form',
        component: () =>
          import('@/views/recruitment/hiring-request/FormView.vue'),
        meta: {
          isDashboard: true,
          model: 'hiring-request',
          isForm: true,
        },
      },
      {
        path: '/jobs',
        name: 'jobs-list',
        component: () => import('@/views/recruitment/jobs/Jobs.vue'),
        meta: {
          isDashboard: true,
          model: 'jobs',
          isForm: true,
        },
      },
      {
        path: '/jobs-form/:id?',
        name: 'jobs-form',
        component: () => import('@/views/recruitment/jobs/FormView.vue'),
        meta: {
          isDashboard: true,
          model: 'jobs',
          isForm: true,
        },
      },
      {
        path: '/job/:id',
        name: 'JobDetails',
        component: () => import('@/views/recruitment/jobs/JobDetails.vue'),
        meta: {
          isDashboard: true,
          model: 'jobs',
          isForm: true,
        },
      }, {
        path: '/application-details/:id',
        name: 'application-details',
        component: () => import('@/views/recruitment/jobs/ApplicationDetails.vue'),
        meta: {
          isDashboard: true,
          model: 'employeeassessmentlinetype',
        },
      },
      {
        path: '/group',
        name: 'groups-list',
        component: () => import('@/views/groups/ListView.vue'),
        meta: {
          isDashboard: true,
        },
      },

      {
        path: '/group',
        name: 'groups-list',
        component: () => import('@/views/groups/ListView.vue'),
        meta: {
          isDashboard: true,
        },
      },
      {
        path: '/user',
        name: 'user-list',
        component: () => import('@/views/user/ListView.vue'),
        meta: {
          isDashboard: true,
        },
      },
      {
        path: '/notification',
        name: 'user-notification-list',
        component: () => import('@/views/notifications/ListView.vue'),
        meta: {
          isDashboard: true,
        },
      },
      {
        path: '/log-entry',
        name: 'log-entry-list',
        component: () => import('@/views/logs/ListView.vue'),
        meta: {
          isDashboard: true,
        },
      },
      {
        path: '/user/form/:id?',
        name: 'user-form',
        component: () => import('@/views/user/FormView.vue'),
        meta: {
          isDashboard: true,
        },
      },
      {
        path: '/permission',
        name: 'permissions-list',
        component: () => import('@/views/permissions/ListView.vue'),
        meta: {
          isDashboard: true,
        },
      },
      {
        path: '/access-denied',
        name: 'permission-denied',
        component: () => import('@/views/miscellaneous/AccessDenied.vue'),
        meta: {
          isDashboard: true,
        },
      },
    ],
  },

  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/errors/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/login/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/coming-soon',
    name: 'misc-coming-soon',
    component: () => import('@/views/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'user',
    },
  },

  {
    path: '/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/errors/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'full',
    },
  },
];
