import Vue from 'vue';

import router from '@/router';
import axios from 'axios';
import { gerOrCreateSignature } from '@/utils/localStorage';
import { handleAPIException } from '@/utils/api/exception_handlers';

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

instance.interceptors.request.use(
  (config) => {
    console.log(instance.interceptors.request)
    config.headers.Signature = gerOrCreateSignature();
    if (localStorage.getItem('access_token')) {
      config.headers.Authorization = `JWT ${localStorage.getItem(
        'access_token'
      )}`;
    }
    return config;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

instance.exceptionHandler = async (callback) => {
  try {
    return await callback();
  } catch (err) {
    handleAPIException(err);
  }
};

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lies within the range of 2xx causes this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    handleAPIException(error);
    console.log('hi im here');

    if (error.response == undefined) {
      console.log('invalid eror was passed to axios', error);
      return;
    }
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }

    const refreshToken = localStorage.getItem('refresh_token');
    if (refreshToken) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      // localStorage.removeItem('signature');

      return axios
        .post(`${process.env.VUE_APP_BASE_URL}/jwt-token/refresh/`, {
          refresh: refreshToken,
        })
        .then((response) => {
          const newTokens = response.data;
          const newAccessToken = newTokens.access;
          const newRefreshToken = newTokens.refresh;

          localStorage.setItem('access_token', newAccessToken);

          if (newRefreshToken) {
            localStorage.setItem('refresh_token', newRefreshToken);
          }

          error.config.headers.Authorization = `JWT ${newAccessToken}`;
        })
        .catch((error) => {
          // when fetching new access token failed
          router.push('/login');
        });
    } else {
      // when no refresh token found in the local storage
      router.push('/login');
    }

    return Promise.reject(error);
  }
);

// this add just for display json data
Vue.prototype.$http = instance;

export default instance;
