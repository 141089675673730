import moment from 'moment';
import axios from '@axios';
import store from '@/store';

export const formatDate = (date) => {
  return moment(date).format('YYYY-MM-DD hh:mm');
};

export const hasPermissions = (requiredPermissions) => {
  const user = store.state.auth.user;

  // Check if user and user.permissions are defined
  if (user && user.permissions) {
    return requiredPermissions.every((permission) =>
      user.permissions.includes(permission)
    );
  } else {
    // Handle the case where user or user.permissions is undefined
    return false;
  }
};

export const getOrSetStore = (state, keyName, endpoint, commitCallback) => {
  return new Promise((resolve, reject) => {
    let currentObject = state[keyName];
    if (Object.keys(currentObject).length === 0) {
      axios
        .get(endpoint)
        .then((response) => {
          const data = response.data;
          commitCallback(data);
          resolve(data);
        })
        .catch(() => {
          const data = {};
          commitCallback(data);
          resolve(data);
        });
    } else {
      resolve(currentObject);
    }
  });
};
