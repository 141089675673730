import axios from '@axios';
import router from '@/router';

export default {
  namespaced: true,
  state: {
    data: {},
  },

  mutations: {
    SET_ATTENDANCE_DATA(state, { data }) {
      state.data = data;
    },
  },

  actions: {
    getAttendance({ commit }) {
      return axios
        .get('attendance/is-checked-in/')
        .then((response) => {
          commit('SET_ATTENDANCE_DATA', { data: response.data });
          return response;
        })
        .catch(() => {});
    },
    attendaceCheck({ commit }, fd) {
      return axios
        .post('attendance-check/', fd)
        .then((response) => {
          return response;
        })
        .catch(() => {});
    },
  },
};
