import axios from '@axios';
import router from '@/router';
import { getOrSetStore } from '@/utils/helpers';

export default {
  namespaced: true,
  state: {
    user: {},
    employee: {},
  },

  mutations: {
    SET_USER_LOGIN(state, { data }) {
      state.user = data;
    },
    SET_EMPLOYEE_RECORD(state, { data }) {
      state.employee = data;
    },
    SET_USER_DATA(state, { data }) {
      state.user = data;
    },
    RESET_STATE(state) {
      state.user = {};
      state.employee = {};
    },
  },
  actions: {
    async login({}, fd) {
      const response = await axios.post('/jwt-token/', fd);
      if (response.status == 200) {
        const newAccessToken = response.data.access;
        const newRefreshToken = response.data.refresh;
        localStorage.setItem('access_token', newAccessToken);
        localStorage.setItem('refresh_token', newRefreshToken);
      }
      return response;
    },

    isUserAdmin({ dispatch }) {
      return dispatch('getProfile')
        .then((userData) => {
          return (
            userData.permissions &&
            userData.permissions.includes('base.view_admin_dahsboard')
          );
        })
        .catch((err) => {
          return false;
        });
    },

    getProfile({ state, commit }) {
      return getOrSetStore(state, 'user', '/me/', (newData) => {
        commit('SET_USER_DATA', { data: newData });
      }).then(() => {
        return state.user;
      });
    },
    getEmployeeRecord({ commit }) {
      return axios
        .get('me-employee/ ')
        .then((response) => {
          commit('SET_EMPLOYEE_RECORD', { data: response.data });
          return response;
        })
        .catch(() => {
          router.push('/login');
        });
    },

    logout({ commit }) {
      commit('RESET_STATE');
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('userData');
      // localStorage.removeItem('signature');
      router.push('/login');
    },
  },
};

